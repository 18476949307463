/* Footer.css */
footer {
    text-align: center;
    width: 100%;
    padding: 20px;
    background: #fff; /* Ensure the footer background matches the app */
    box-shadow: none; /* Optional: remove box shadow if you want a flat design */
    position: relative; /* Changed from absolute to relative */
  }
  
  .footer-text {
    display: block; /* Creates a newline */
  }
  