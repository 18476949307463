body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #fff; /* Set background color to white */
}

h2 {
  color: #333;
}
