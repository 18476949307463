/* Header.css */
.header {
    width: 100%; /* Full width */
    padding: 20px 0; /* Padding at the top and bottom */
    box-shadow: none; /* Optional: remove box shadow if you want a flat design */
    display: flex;
    justify-content: center; /* Center the logo horizontally */
  }
  
  .logo {
    width: auto; /* Width can be auto for intrinsic aspect ratio */
    height: 80px; /* Adjust height to make the logo smaller */
  }
  