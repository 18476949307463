/* Add this CSS to your styles.css file or equivalent */

/* Custom toast container class */
.toast-container {
    width: 300px; /* Same as form width */
    margin-left: auto;
    margin-right: auto;
  }
  
/* Optional: Additional styling to match your form style */
.toast {
    border-radius: 50%;
    font-family: Arial, sans-serif;
}
  